export const FORM_DISCLAIMER = `By checking the box below, you ("You") acknowledge, warrant, and represent the following to KarmaCare, Inc. ("Us" or "KarmaCare") regarding any and all data, test results, or other information you provide Us (the "Data"):

(i)                  The Data You upload and send to Us is your own and you are not uploading any Data for or on behalf of another party.

(ii)                You have the full right, power and authority to upload and send your Data to Us.

(iii)               KarmaCare is collecting your Data as a representative and at the direction of your employer or such other third party organization with which You are affiliated or hereby providing us with permission to share your Data with.

(iv)               To the maximum extent permitted by law, You waive any claim You might have against KarmaCare with respect to, or arising out of, any usage or transfer of your Data by Us or a third party. Neither KarmaCare nor any of its respective directors, officers, employees, agents or affiliates shall be liable for any claim with respect to, or arising out of, any usage or transfer of your Data by Us or a third party.

(v)                You explicitly consent to KarmaCare sharing your Data with your employer and any other organization you have authorized or shall authorize in the future.

(vi)               You agree to the Site's Terms of Use (found at https://www.karmacare.io/terms-site) and any other terms and conditions incorporated therein.
`;
