import Vue from "vue";
import App from "./App.vue";
import VueFormulate from "@braid/vue-formulate";
import VueRouter from "vue-router";

Vue.config.productionTip = false;

Vue.use(VueFormulate);
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
