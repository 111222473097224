<template>
  <div>
    <CustomForm />
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import CustomForm from "./components/CustomForm.vue";

export default {
  name: "App",
  components: {
    // HelloWorld,
    CustomForm,
  },
};
</script>

<style lang="scss">
@import "../node_modules/@braid/vue-formulate/themes/snow/snow.scss";

html {
  min-height: 100%;
}
body {
  padding: 2em;
  background-image: url("~@/assets/karmacare_trans_bg.png"),
    linear-gradient(-45deg, rgb(255, 62, 150), #ffffff);
  background-repeat: no-repeat;
  background-size: 20em, 100%;
  background-position: center 2em, center center;
}
</style>
