<template>
  <div>
    <div class="inputs" v-if="isSubmitted">
      <h3 class="header">Thank you for submitting your test result.</h3>
      <FormulateInput
        type="submit"
        label="Submit another"
        class="middle"
        @click="reset"
      />
    </div>
    <FormulateForm
      v-show="!isSubmitted"
      class="inputs"
      name="test-upload-form"
      v-model="formValues"
      @submit="submitHandler"
      #default="{ isLoading }"
    >
      <h2 class="header">Test Upload Form</h2>
      <FormulateInput
        type="text"
        placeholder="Anthony"
        name="firstName"
        validation-name="First name"
        label="First Name"
        validation="required"
      />
      <FormulateInput
        type="text"
        placeholder="Fauci"
        name="lastName"
        validation-name="Last Name"
        label="Last Name"
        validation="required"
      />
      <FormulateInput
        type="email"
        name="email"
        placeholder="afauci@niaid.nih.gov"
        label="Email"
        validation="required|email"
      />
      <FormulateInput
        type="text"
        name="organization"
        placeholder="National Institute of Allergy and Infectious Diseases"
        validation-name="Organization"
        label="Company or Organization"
        help="The organization you are submitting this test for. If you are attending another organization's event, please put that company or organization's name."
        validation="required"
      />
      <FormulateInput
        type="date"
        name="dateTestTaken"
        label="What day did you take this test?"
        validation="required"
        validation-name="Date test taken"
      />
      <FormulateInput
        type="image"
        name="testImage"
        validation-name="Photo of test"
        label="Take a photo of your test result."
        help="Take or upload a photo of your test result."
        validation="required"
        :uploader="uploadFile"
        upload-behavior="delayed"
      />
      <FormulateInput
        type="radio"
        name="testResult"
        validation-name="Test result"
        label="What result does your COVID test show?"
        :options="{ negative: 'Negative', positive: 'Positive' }"
        validation="required"
        help=""
      />
      <div class="faux-label">Legal</div>
      <div class="disclaimer">
        {{ formDisclaimer }}
      </div>
      <FormulateInput
        label="Yes, I agree"
        type="checkbox"
        name="agreeTOS"
        validation-name="Agreeing to the terms and conditions"
        validation="required"
        help="We require your explicit consent to share your COVID test results."
      />
      <FormulateInput type="hidden" name="exactTermsLanguage" />
      <FormulateErrors />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        class="middle"
        :label="isLoading ? 'Submitting...' : 'Submit'"
      />
    </FormulateForm>
  </div>
</template>

<style lang="scss">
$fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

.header {
  margin-top: 0;
  font-family: $fonts;
  text-align: center;
}

.middle {
  text-align: center;
}

.inputs {
  background-color: white;
  max-width: 20em;
  padding: 2em;
  margin: 6.5em auto 2em auto;
  border-radius: 0.25em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
}

.faux-label {
  display: block;
  text-align: j;
  line-height: 1.5;
  font-size: 0.9em;
  font-weight: 600;
  font-family: $fonts;
  padding-bottom: 2px;
}

div.disclaimer {
  background-color: rgb(148 148 148 / 18%);
  border-radius: 5px;
  padding: 10px;
  font-family: monospace;
  white-space: pre-line;
  margin-top: 5px;
  margin-bottom: 10px;
  max-height: 10em;
  overflow-y: scroll;
}

div[data-type="radio"] + label.formulate-input-label--after,
div[data-type="checkbox"] + label.formulate-input-label--after {
  font-weight: normal;
}

label {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  margin-bottom: 0.25em;

  svg {
    display: inline-block;
    width: 1.5em;
    fill: #37c392;
    vertical-align: middle;
    margin-left: 0.5em;
  }
}
</style>

<script>
/* eslint-disable no-debugger */
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { FORM_DISCLAIMER } from "../constants/legalese";
import FormData from "form-data";
import { API_SERVER } from "../constants/config";

export default {
  name: "CustomForm",
  methods: {
    reset() {
      this.$formulate.reset("test-upload-form");
      this.isSubmitted = false;
    },
    async submitHandler(data) {
      data.uploadedFileURL = data.testImage[0].url;
      delete data.testImage;
      const response = await fetch(
        `${API_SERVER}/api/v1/files/test-form-submisson`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      if (response.status !== 200) {
        throw `Error submitting form: ${await response.json()}`;
      }

      this.isSubmitted = true;
    },
    async getPresignedUrl(filename) {
      let response;

      // Sometimes the S3 presign returns a 405 method not allowed for unclear reasons,
      // but retries eventually work even though it's the same request.
      for (let i = 0; i < 4; i++) {
        try {
          response = await fetch(
            `${API_SERVER}/api/v1/files/presign-test-result-image`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              credentials: "omit",
              mode: "cors",
              body: JSON.stringify({ filename: filename }),
            }
          );
        } catch (err) {
          console.log(`presign API server error try ${i}, continuing.`);
          continue;
        }

        if (response.status === 200) {
          break;
        }
      }

      const data = await response.json();
      return data;
    },
    // eslint-disable-next-line no-unused-vars
    uploadFile: async function (file, progress, error, option) {
      try {
        const form = new FormData();

        const { url, metadata, fields } = await this.getPresignedUrl(file.name);

        Object.entries(fields).forEach(([field, value]) => {
          form.append(field, value);
        });
        form.append("file", file);
        progress(10);
        const result = await fetch(url, {
          method: "POST",
          body: form,
        });
        progress(100);
        if (result.status === 204) {
          return {
            url: metadata.key,
          };
        }
      } catch (err) {
        error("Unable to upload file");
      }
    },
  },
  data() {
    const prefillItems = ["firstName", "lastName", "email", "organization"]
      .map((i) => {
        if (this.$route.query[i]) {
          return [i, this.$route.query[i]];
        } else {
          return null;
        }
      })
      .filter((i) => i !== null);

    return {
      isSubmitted: false,
      formValues: {
        exactTermsLanguage: btoa(FORM_DISCLAIMER),
        dateTestTaken: new Date().toJSON().slice(0, 10),
        ...Object.fromEntries(prefillItems),
      },
    };
  },
  computed: {
    orgName: function () {
      return this.formValues.organization;
    },
    formDisclaimer: () => FORM_DISCLAIMER,
  },
};
</script>
